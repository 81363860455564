<template>
    <div class="baza-item">
        <baza-item-header :item="item"/>
        <div class="baza-item__info">
            <stamp :code="item.extProps.stamp" :stamp-value="item.extProps.stampValue"/>
            <top-block :item="item"/>
            <div class="bottom">
                <div class="bottom__item customer">
                    <div class="l">
                        <span class="i i-man6 mr8"></span>
                        <div v-if="!(user.groupCode == 'T' || user.groupCode == 'P')" class="o-desc">
                            <span v-if="clientName" v-html="clientName"></span>
                            <span v-if="clientName && clientContactsPhone">, </span>
                            <span v-if="clientContactsPhone && (user.groupCode == 'R' || user.groupCode == 'K')">
                                <a :href="`tel:${clientContactsPhone}`">{{ clientContactsPhone }}</a> (<a
                                href="javascript:void(0);"
                                @click="openSendSms(item, 'sendSms')"><strong>СМС</strong></a>)</span>
                            <span v-else-if="clientContactsPhone">
                                <a :href="`tel:${clientContactsPhone}`">{{ clientContactsPhone }}</a>
                            </span>
                            <span v-if="clientContactsPhone && clientContactsEmail">, </span>
                            <span v-if="clientContactsEmail">{{ clientContactsEmail }}</span>
                            <span v-if="clientContactsSkype && clientContactsEmail">, </span>
                            <span v-if="clientContactsSkype">{{ clientContactsSkype }}</span>

                            <span class="i-wrapper pencil ml8"
                                  v-if="item.props.isEditContacts && !(user.groupCode == 'T' || user.groupCode == 'P') && !isNPSMeasure"
                                  @click="openPopup(item, popups.customerData.name)"><span
                                class="i i-pencil2 i--blue"></span><span
                                class="i i-pencil2-active i--blue"></span></span>
                        </div>
                        <span v-else class="text-color-grey text-italic">Недоступно</span>
                        <div class="o-mob">
                            <div>{{ clientName }}</div>
                            <div><a :href="'tel:' + item.props.clientPhone">{{ item.props.clientPhone }}</a></div>
                            <div>{{ item.props.clientEmail }}</div>
                            <div>skype: {{ item.props.clientSkype }}</div>
                        </div>
                    </div>
                    <div class="r df">
                        <div
                            v-if="hasState(repairNoteState) && (user.groupCode == 'D' || user.groupCode == 'V' || user.groupCode == 'M' || user.groupCode === 'TD') && !isNPSMeasure"
                            class="item-btn mr8" @click="openPopup(item, popups.repairNote.name)">
                            <span class="i i-pencil"></span>
                        </div>
                        <div v-if="!(user.groupCode == 'T' || user.groupCode == 'P')" class="item-btn"
                             @click="openPopup(item, popups.customerCharacter.name)">
                            <span class="i i-customer"></span>
                        </div>
                    </div>
                </div>
                <div class="bottom__item addr">
                    <div class="l">
                        <span class="i i-marker mr8"></span>
                        <span v-if="!(user.groupCode == 'T' || user.groupCode == 'P')"
                              v-html="item.props.address"></span>
                        <span v-else class="text-color-grey text-italic">Недоступно</span>
                        <span class="i-wrapper pencil ml8"
                              v-if="item.props.isEditAddress && !(user.groupCode == 'T' || user.groupCode == 'P') && !isNPSMeasure"
                              @click="openPopup(item, popups.objectAddr.name)"><span class="i i-pencil2 i--blue"></span><span
                            class="i i-pencil2-active i--blue"></span></span>
                    </div>
                    <div class="r" v-if="!(user.groupCode == 'T' || user.groupCode == 'P')">
                        <span class="copy o-mob" @click="copyTextToClipboard(item.props.address)"
                              v-if="item.props.typeMetering === 'normal'"></span>
                        <div
                            v-if="user.groupCode === 'S' || user.groupCode === 'V' || user.groupCode === 'K'|| user.groupCode === 'R' || user.groupCode === 'D' || user.groupCode === 'TD' || user.groupCode === 'N' || user.groupCode === 'AR'"
                            class="item-btn" :is-loader-in="buttons.log === 'load'" :type="buttons.log"
                            @click="openPopupLog(item)">
                            <span class="i i-log"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import Stamp from '@/components/BazaItem/Stamp.vue';
import TopBlock from '@/components/BazaItem/TopBlock.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { convertNewLine, copyTextToClipboard } from '@/helpers/util/helper';
import TopItemBlockReward from '@/components/BazaItem/TopItemBlockReward.vue';
import BazaItemHeader from '@/components/BazaItem/Header.vue';

export default {
    name: 'BazaItem',
    props: {
        item: {},
    },
    components: {
        Tooltip,
        SubmitBtn,
        Splide,
        SplideSlide,
        Stamp,
        TopBlock,
        TopItemBlockReward,
        BazaItemHeader,
    },
    data() {
        return {
            appPath: process.env.VUE_APP_PATH_BACK,
            buttons: {
                measureDetail: 'normal',
                conceptConfirm: 'normal',
                measureTime: 'normal',
                resumeMeasure: 'normal',
                log: 'normal',
                sendSms: 'normal',
            },
            loadContactGallery: false,
            isShowedViewer: false,
            isShowedViewerConcept: false,
            calendarShow: false,
            dates: [],
            repairNoteState: [
                'repair_think_*', 'repair_reject_*', 'repair_prepay_*', 'repair_aside_*', 'repair_in_process_*', 'repair_terminate_*', 'repair_finish_*',
            ],
        };
    },
    mounted() {
        if (this.$route.query.id) {
            if (this.item.id == this.$route.query.id) {
                this.openMeasureDetail(this.item);
                this.$router.push(this.$route.path);
            }
        }
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            user: state => state.auth.user,
            workers: state => state.requests.workers,
            menuList: state => state.menus.itemsTop,
        }),
        section() {
            return this.$route.name;
        },
        clientName() {
            let name = this.item.props.clientSurname + ' ' + this.item.props.clientName + ' ' + this.item.props.clientPatronymic;
            return name.trim();
        },
        clientContactsPhone() {
            return this.item.props.clientPhone;
        },
        clientContactsSkype() {
            let skype = this.item.props.clientSkype;
            return skype ? 'skype: ' + skype : 'нет скайпа';
        },
        clientContactsEmail() {
            return this.item.props.clientEmail;
        },
        isNPSMeasure() {
            return this.$route.name === 'nps-measure' || this.$route.name === 'nps-measure-current';
        },
    },
    emits: ['splide:hidden', 'splide:move', 'splide:arrows:updated', 'splide:pagination:updated', 'splide:moved', 'splide:active', 'splide:visible', 'splide:inactive'],
    methods: {
        copyTextToClipboard(text) {
            copyTextToClipboard(text);
        },
        convertNewLine(text) {
            return convertNewLine(text);
        },
        hasState(list) {
            let state = this.item.extProps.state;
            for (let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
        openPopup(item, popup) {
            if ((popup == this.popups.repairTerminate.name || popup == this.popups.repairFinish.name) && item.extProps.stamp == 'order_not_submitted') {
                this.$store.dispatch('popups/open', { name: this.popups.repairFinishError.name, request: item });
            } else {
                this.$store.dispatch('popups/open', { name: popup, request: item });
            }

        },
        openSendSms(item, type) {
            this.buttons.sendSms = 'load';
            this.$store.dispatch('popups/getData', { id: item.id, modalData: type }).then(response => {
                this.buttons.sendSms = 'normal';
                this.$store.commit('requests/setItemProps', {
                    item: this.item, props: {
                        smsSendText: response.items,
                    },
                });
                this.$nextTick(() => {
                    this.$store.dispatch('popups/open', { name: this.popups.sendSms.name, request: item });
                });
            });
        },
        openMeasureDetail(item) {
            if (item.contract) {
                this.$store.dispatch('popups/open', { name: this.popups.measureDetail.name, request: item });
            } else {
                this.buttons.measureDetail = 'load';
                this.$store.dispatch('popups/getData', {
                    id: item.props.contractId,
                    modalData: 'MeasureDetail',
                }).then(response => {
                    this.buttons.measureDetail = 'normal';
                    this.$store.commit('requests/setItemProps', {
                        item: this.item, props: {
                            contract: response.items,
                        },
                    });
                    this.$nextTick(() => {
                        this.$store.dispatch('popups/open', { name: 'measureDetail', request: item });
                    });
                });

            }
        },
        openPopupLog(item) {
            this.buttons.log = 'load';
            this.$store.dispatch('popups/getData', { id: item.id, modalData: 'Log' }).then(response => {
                this.buttons.log = 'normal';
                this.$store.commit('requests/setItemProps', {
                    item: this.item, props: {
                        log: response.items,
                    },
                });
                this.$store.dispatch('popups/open', { name: this.popups.log.name, request: item });
                document.getElementsByTagName('body')[0].classList.add('noscroll');
            });
        },
    },
};
</script>
