<template>
    <div class="worker-select baza-select" :class="{error: error, opened: opened}">
        <div class="text-color-grey text-uppercase text-size-xs">
            {{ title }}
        </div>
        <div class="mt8 mb8 choose-block" @click.stop="toggle">
            <div class="text-bold text-size-xxl choosen-elem" v-if="modelValue">
                <span class="mr8 name">{{ modelValue }} мес.
                  <div class="choosen-arrow">
                  </div>
                </span>
            </div>
            <a href="javascript:void(0);" class="choose text-bold text-size-xxl" v-else>
                Выбрать
                <div class="arrow">
                </div>
            </a>
            <div class="select-list" v-if="opened">
                <div class="select-item df df-space-between" v-for="i in 24" @click="changeValue(i)">
                    <span class="i i-ok2" v-if="i === currentIndex"></span>
                    <div class="name">{{ i }} мес.</div>
                    <div class="count text-right">
                        {{ months[getArrDate(i)[0]] }} {{ getArrDate(i)[1] }}
                    </div>
                </div>
            </div>
        </div>
        <div class="text-color-grey text-size-xs dif text-uppercase" v-if="modelValue">
            {{ months[getArrDate(modelValue)[0]] }} {{ getArrDate(modelValue)[1] }}
        </div>
        <div class="error-text" v-if="error && errorText.length > 0">
            <span class="i i-info3 i--red mr4"></span>
            <span class="text-uppercase">{{ errorText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BazaSelect',
    props: {
        modelValue: { default: '' },
        error: false,
        title: { default: '' },
        type: { default: '' },
        errorText: { default: '' },
    },
    data: function () {
        return {
            opened: false,
            currentIndex: '',
            months: ['ЯНВАРЬ', 'ФЕВРАЛЬ', 'МАРТ', 'АПРЕЛЬ', 'МАЙ', 'ИЮНЬ', 'ИЮЛЬ', 'АВГУСТ', 'СЕНТЯБРЬ', 'ОКТЯБРЬ', 'НОЯБРЬ', 'ДЕКАБРЬ'],
        };
    },
    emits: ['update:modelValue', 'toggled'],
    mounted() {

    },
    computed: {},
    methods: {
        getArrDate(i) {
            let today = new Date();
            today.setDate(1);
            today.setMonth(today.getMonth() + i);
            return [today.getMonth(), today.getFullYear()];
        },
        toggle() {
            this.opened = !this.opened;
            this.$emit('toggled');
        },
        changeValue(index) {
            this.currentIndex = index;
            this.$emit('update:modelValue', index);
        },
    },
    watch: {},
    directives: {
        'clickoutside': {
            mounted: function (el, binding, vNode) {
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name;
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                    if (compName) { warn += `Found in component '${compName}'`; }
                    //console.warn(warn);
                }
                const bubble = binding.modifiers.bubble;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;
                document.addEventListener('click', handler);
            },

            unmounted: function (el, binding) {
                document.removeEventListener('click', el.__vueClickOutside__);
                el.__vueClickOutside__ = null;
            },
        },
    },
};
</script>
