<template>
    <div>
        <div class="popup__content">
            <div class="add-worker__top mb24">
                <div class="text-size-l text-bold text-color">Пользователь</div>
            </div>
            <div style=" width: 100%;" :class="{disabled: false}">
                <div class="df df-space-between mb16">
                    <div style="width: 364px;">
                        <input-multi
                            @chunk="setPersonInput"
                            name="worker-name"
                            :disabled="false"
                            :is-error="form.worker.name.error"
                            :error-text="form.worker.name.errorText"
                            v-model="form.worker.name.value"
                            :fields="form.worker.name.fields"
                            @focus="removeError(form.worker.name)"
                            :placeholderInput="'Фамилия Имя Отчество'"
                            @blur="blur(form.worker.name)"
                            :placeholder="'Фамилия Имя Отчество'"></input-multi>
                    </div>
                    <div style="width: 364px;">
                        <input-styled
                            name="worker-num"
                            :disabled="false"
                            :placeholder="'Номер сотрудника'"
                            :placeholderInput="'Номер сотрудника'"
                            v-model="form.worker.num.value"
                            @focus="form.worker.num.error=false"
                            :error-text="form.worker.num.errorText"
                            :is-error="form.worker.num.error"
                            :mask="{mask: '9{1,3}', placeholder: '',rightAlign: false,showMaskOnHover : false}">
                        </input-styled>
                    </div>
                </div>
                <div class="df df-space-between mb32">
                    <div style="width: 234px;">
                        <input-styled
                            name="worker-phone"
                            :disabled="false"
                            :placeholder="'Телефон'"
                            :placeholderInput="'Телефон'"
                            v-model="form.worker.phone.value"
                            @focus="form.worker.phone.  error=false"
                            :error-text="form.worker.phone.errorType === 'use' ? 'Этот номер уже существует':form.worker.phone.errorType === 'mobile' ? 'Только мобильные номера':''"
                            :is-error="form.worker.phone.error"
                            :mask="{mask:'+7(999)999-99-99',showMaskOnHover: false}">
                        </input-styled>
                    </div>

                    <div style="width: 234px">
                        <input-styled
                            name="worker-post"
                            :disabled="false"
                            :placeholder="'E-mail'"
                            :placeholderInput="'E-mail'"
                            v-model="form.worker.email.value"
                            :error-text="form.worker.email.errorType === 'use' ? 'Этот email уже существует':form.worker.email.errorType === 'rule' && form.worker.email.value.length ? 'Некорректный email':''"
                            @focus="form.worker.email.error=false"
                            :is-error="form.worker.email.error">
                        </input-styled>
                    </div>
                    <div class="" style="width: 234px; height: 40px;">
                        <input-styled
                            v-if="false"
                            name="worker-post"
                            :placeholder="'Роль'"
                            :disabled="true"
                            :placeholderInput="'Роль'"
                            v-model="form.selectGroup.value"
                            @focus="form.selectGroup.error=false"
                            :is-error="form.selectGroup.error">
                        </input-styled>
                        <input-select-default
                            v-else
                            @change="changeRole"
                            @open="form.selectGroup.error=false"
                            :tag="'div'"
                            :class="'choose-role'"
                            :disabled="false"
                            :isError="form.selectGroup.error"
                            v-model="form.selectGroup.value"
                            :options="selectOptionsRoles"
                            :placeholder="'Роль'"
                            :list-style="{'max-height': 'none'}"></input-select-default>
                    </div>
                </div>

                <div class="mt56">
                    <div class="text-bold text-size-ml">
                        {{ optionsRolesTitle }}
                    </div>
                    <div class="df mt24">
                        <div v-if="selectOptionsRolesType === 'radio'" @click="checkDisableError()"
                             class="checkboxes-access df">
                            <div @click="accessErrorChange()" class="checkbox-access" v-for="team in teamList"
                                 :key="team.id">
                                <input @change="form.access.error= false" type="radio" :disabled="checkboxDisabled"
                                       :class="{'error': form.access.error}" :value="team.id" name="access"
                                       :id="'radio'+team.id" v-model="form.access.value">
                                <label :for="'radio'+team.id">
                                    {{ team.title }}
                                </label>
                            </div>
                        </div>
                        <div @click="checkDisableError()" v-else class="checkboxes-access df">
                            <div class="checkbox-access" v-for="(team, index) in teamList" :key="team.id">
                                <input @change="accessErrorChange()" type="checkbox" :disabled="checkboxDisabled"
                                       :class="{'error': form['access' + ((index > 0) ? index + 1 : '')].error}"
                                       :value="team.id" name="access" :id="'radio'+team.id"
                                       v-model="form.accessOptions.value">
                                <label :for="'radio'+team.id">
                                    {{ team.title }}
                                </label>
                            </div>
                        </div>

                        <span class="text-size-xs text-color-grey text-italic ml8" style="margin-top: -8px">
		                    Чтобы создать новую команду,<br>
							обратитесь к разработчикам
		                </span>
                    </div>
                </div>
            </div>
            <div class="df df-space-between mt48">
                <submit-btn class="" @click.native="send" tag="button" :type="btnType">
                    <span class="i i-ok7 mr4"></span>Сохранить
                </submit-btn>
                <!--<submit-btn v-else class="" @click.native="close()" :type="form.btnType">
                    <span class="i mr4" style="margin-top: -2px;" :class="{'i-ok7':form.btnType==='normal'}"></span>Сохранить
                </submit-btn>-->

                <div class="add-worker__link remove" v-if="editUser.id">
                    <a @click="block()" v-if="editUser.blocked != 1" href="javascript:void(0);"
                       class="link-icon link-icon__modal">
                        <span class="i i-stop"></span>
                        <span class="text">Заблокировать</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import InputStyled from '@/components/forms/InputStyled.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import InputSelectDefault from '@/components/forms/InputSelectDefault.vue';
import InputMulti from '@/components/forms/InputMulti.vue';
import validate from '@/helpers/validate';

export default {
    name: 'WorkerModal',
    components: {
        InputStyled,
        SubmitBtn,
        InputSelectDefault,
        InputMulti,
    },
    props: {
        usersGroupedCurrent: {
            default() {
                return {};
            },
        },
    },
    mounted() {

        if (this.editUser.firstName) {
            this.form.worker.name.value = this.editUser.lastName + ' ' + this.editUser.firstName + ' ' + this.editUser.secondName;
            this.form.worker.name.fields.lastName.value = this.editUser.lastName;
            this.form.worker.name.fields.firstName.value = this.editUser.firstName;
            this.form.worker.name.fields.secondName.value = this.editUser.secondName;
        }

        this.form.worker.num.value = this.editUser.number;
        this.form.worker.phone.value = this.editUser.phone;
        this.form.worker.email.value = this.editUser.email;
        this.form.selectGroup.value = this.selectGroup;
        if (typeof (this.editUser.teamId) === 'object') {
            this.form.accessOptions.value = this.editUser.teamId;
        } else {
            this.form.access.value = this.editUser.teamId;
        }

        if (this.form.selectGroup.value === 'Технолог' || this.form.selectGroup.value === 'Проверяющий') {
            this.selectOptionsRolesType = 'checkbox';
        } else {
            this.selectOptionsRolesType = 'radio';
        }
    },
    data() {
        return {
            btnType: 'normal',
            selectOptionsRoles: [
                { id: 30, name: 'Руководитель' },
                { id: 31, name: 'Координатор' },
                { id: 32, name: 'Замерщик' },
                { id: 33, name: 'Технолог' },
                { id: 34, name: 'Проверяющий' },
                { id: 52, name: 'Ассистент руководителя' },
                /*{id: 35, name: 'Владелец'},
                {id: 36, name: 'Директор'},
                {id: 44, name: 'Бухгалтер'},*/
            ],
            selectOptionsRolesType: 'radio',
            form: {
                selectGroup: {
                    value: '',
                    error: false,
                },
                accessOptions: {
                    value: [],
                    error: false,
                },
                access: {
                    value: [],
                    error: false,
                },
                access2: {
                    value: [],
                    error: false,
                },
                access3: {
                    value: [],
                    error: false,
                },
                access4: {
                    value: [],
                    error: false,
                },
                access5: {
                    value: [],
                    error: false,
                },
                worker: {
                    name: {
                        name: 'workerName',
                        value: '',
                        fields: {
                            lastName: {
                                name: 'lastName',
                                value: '',
                                delimiter: ' ',
                                placeholder: 'Фамилия',
                            },
                            firstName: {
                                name: 'firstName',
                                value: '',
                                delimiter: ' ',
                                placeholder: 'Имя',
                            },
                            secondName: {
                                name: 'secondName',
                                value: '',
                                delimiter: '',
                                placeholder: 'Отчество',
                            },
                        },
                        required: true,
                        error: false,
                        errorText: '',
                        disabled: false,
                        focus: false,
                        sortScroll: 7,
                    },
                    phone: {
                        value: '',
                        error: false,
                        errorType: 'empty',
                    },
                    num: {
                        value: '',
                        error: false,
                        errorType: 'empty',
                    },
                    email: {
                        value: '',
                        error: false,
                        errorType: 'empty',
                    },

                },
            },
        };
    },
    methods: {
        accessErrorChange() {
            this.form.access.error = false;
            this.form.accessOptions.error = false;
        },
        checkDisableError() {
            this.form.selectGroup.error = false;
            if (this.checkboxDisabled) {
                setTimeout(() => {
                    this.form.selectGroup.error = true;
                }, 10);
            }
        },
        changeRole() {
            this.form.selectGroup.error = false;
            this.$nextTick(() => {
                if (this.form.selectGroup.value === 'Технолог' || this.form.selectGroup.value === 'Проверяющий') {
                    this.selectOptionsRolesType = 'checkbox';
                } else {
                    this.selectOptionsRolesType = 'radio';
                }
            });

        },
        send() {
            let error = false;

            for (let field in this.form.worker) {
                if (this.form.worker[field].error) {
                    this.form.worker[field].error = false;
                    setTimeout(() => {
                        this.form.worker[field].error = true;
                    });
                    error = true;
                } else {
                    let errorField = false;

                    if (typeof (this.form.worker[field].value) === 'undefined' || this.form.worker[field].value.length == 0) {
                        errorField = true;
                    } else {
                        if (field == 'phone' && !validate.isPhone(this.form.worker[field].value)) {
                            errorField = true;
                        }
                        if (field == 'email' && !validate.isEmail(this.form.worker[field].value)) {
                            errorField = true;
                        }
                    }
                    if (errorField) {
                        error = true;
                        this.form.worker[field].error = true;
                    }
                }
            }

            if ((typeof (this.form.access.value) === 'undefined' || this.form.access.value.length == 0) &&
                (typeof (this.form.accessOptions.value) === 'undefined' || this.form.accessOptions.value.length == 0)) {
                error = true;
                this.form.access.error = true;
                this.form.accessOptions.error = true;
            }

            if (typeof (this.form.selectGroup.value) === 'undefined' || this.form.selectGroup.value.length == 0) {
                error = true;
                this.form.selectGroup.error = true;
            }

            if (!error) {
                this.btnType = 'load';
                let fields = {};

                if (this.editUser.id) {
                    fields['is_create'] = false;
                    fields['id'] = this.editUser.id;
                } else {
                    fields['is_create'] = true;
                    fields['id'] = 0;
                }

                fields['firstName'] = this.form.worker.name.fields.firstName.value;
                fields['secondName'] = this.form.worker.name.fields.secondName.value;
                fields['lastName'] = this.form.worker.name.fields.lastName.value;
                fields['num'] = this.form.worker.num.value;
                fields['phone'] = this.form.worker.phone.value;
                fields['email'] = this.form.worker.email.value;
                fields['group'] = this.getGroupId(this.form.selectGroup.value);

                if (this.form.accessOptions.value.length > 0) {
                    fields['team'] = this.form.accessOptions.value;
                } else {
                    fields['team'] = typeof (this.form.access.value) === 'object' ? this.form.access.value[0] : this.form.access.value;
                }

                this.$store.dispatch('staff/saveUser', fields).then((res) => {
                    if (res.error == '410') {
                        this.form.worker.phone.error = true;
                        this.form.worker.phone.errorType = 'use';
                    } else {
                        this.$emit('updateList');
                        setTimeout(() => {
                            this.$store.commit('popups/close', { name: this.popups.worker.name });
                        }, this.options.delayPopupClose);
                    }
                    this.btnType = 'normal';
                }, () => { this.btnType = 'normal'; });
            }
        },
        getGroupId: function (groupName) {
            let groupId = '';

            for (let i in this.selectOptionsRoles) {
                if (this.selectOptionsRoles[i].name == groupName) {
                    groupId = this.selectOptionsRoles[i].id;
                }
            }

            return groupId;
        },
        setPersonInput: function (field) {
            if (field.value !== undefined && field.name !== undefined) {
                this.form.worker.name.fields[field.name].value = field.value;
            }
        },
        blur(field) {
            field.focus = false;
        },
        removeError: function (field) {
            field.error = false;
            field.focus = true;
        },
        close() {
            this.$store.commit('popups/close', { name: this.popups.worker.name });
        },
        repeatFieldError(field) {
            if (this.form.worker[field].error) {
                this.form.worker[field].error = false;
                setTimeout(() => {
                    this.form.worker[field].error = true;
                });
            } else {
                this.form.worker[field].error = true;
            }
        },
        block() {
            this.$store.commit('popups/open', { name: this.popups.blockWorker.name });
        },
        unBlock() {
            this.$store.commit('popups/open', { name: this.popups.unBlockWorker.name });
        },
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            editUser: state => state.staff.editUser,
            options: state => state.options,
        }),

        optionsRolesTitle() {
            let title = 'Команда';
            if (typeof (this.form.selectGroup.value) !== 'undefined' || this.form.selectGroup.value.length > 0) {
                if (this.form.selectGroup.value === 'Технолог' || this.form.selectGroup.value === 'Проверяющий') {
                    title = 'Доступен командам';
                }
            }
            return title;
        },
        checkboxDisabled() {
            if (this.editUser.id && this.selectOptionsRolesType == 'radio') {
                return true;
            }

            if (typeof (this.form.selectGroup.value) === 'undefined' || this.form.selectGroup.value.length == 0) {
                return true;
            } else {
                return false;
            }

        },
        teamList() {
            let teams = [];

            for (let i in this.options.items.teams) {
                if (this.options.items.teams[i].id != 0 && this.options.items.teams[i].id != 'all') {
                    teams.push(this.options.items.teams[i]);
                }
            }

            return teams;
        },
        selectGroup() {
            let group = '';

            for (let i in this.selectOptionsRoles) {
                if (this.selectOptionsRoles[i].id == this.editUser.groupId) {
                    group = this.selectOptionsRoles[i].name;
                }
            }

            return group;
        },
    },
};
</script>

<style scoped>

</style>
